.tech-stack {
  color: var(--accent);
  font-size: 1.1rem;
  font-weight: normal;
}

.arrow {
  width: 15px;
  filter: brightness(80%);
  margin-left: 10px;
}

li {
  margin-top: 30px;
}

a {
  color: var(--accent);
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: bold;
}

